import FuseUtils from "@fuse/utils/FuseUtils";
import axios, { AxiosError, AxiosResponse } from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";
import UserType, { UserSignupType } from "app/store/user/UserType";
import { PartialDeep } from "type-fest";
import {
	IForgotPasswordResponse,
	IUserData,
	IUserLoginResponse,
	IUserSignupResponse,
} from "@fuse/models/user.models";
import jwtServiceConfig from "./jwtServiceConfig";
import { AnyIfEmpty } from "react-redux";
/* eslint-disable camelcase, class-methods-use-this */

/**
 * The JwtService class is a utility class for handling JSON Web Tokens (JWTs) in the Fuse application.
 * It provides methods for initializing the service, setting interceptors, and handling authentication.
 */
class JwtService extends FuseUtils.EventEmitter {
	/**
	 * Initializes the JwtService by setting interceptors and handling authentication.
	 */
	init() {
		this.setInterceptors();
		this.handleAuthentication();
		_setAxiosSession();
	}

	/**
	 * Sets the interceptors for the Axios instance.
	 */
	setInterceptors = () => {
		axios.interceptors.response.use(
			(response: AxiosResponse<unknown>) => response,
			(err: AxiosError) =>
				new Promise(() => {
					if (err?.response?.status === 401 && err.config) {
						// if you ever get an unauthorized response, logout the user
						this.emit("onAutoLogout", "Invalid access_token");
						_setSession(null);
					}
					throw err;
				})
		);
	};

	/**
	 * Handles authentication by checking for a valid access token and emitting events based on the result.
	 */
	handleAuthentication = () => {
		const access_token = getAccessToken();

		if (!access_token) {
			this.emit("onNoAccessToken");

			return;
		}

		if (isAuthTokenValid(access_token)) {
			_setSession(access_token);
			this.emit("onAutoLogin", true);
		} else {
			_setSession(null);
			this.emit("onAutoLogout", "access_token expired");
		}
	};

	/**
	 * Creates a new user account.
	 */
	createUser = (data: {
		firstName: UserSignupType["firstName"];
		lastName: UserSignupType["firstName"];
		email: UserSignupType["email"];
		phoneNumber: UserSignupType["phoneNumber"];
		password: UserSignupType["password"];
	}) =>
		new Promise((resolve, reject) => {
			axios
				.post(`${process.env.VITE_APP_API_BASE_URL}Users/signup`, data)
				.then((response: AxiosResponse<IUserSignupResponse>) => {
					if (response.data.errors.length > 0) {
						reject([{ type: "email", message: response.data.errors[0] }]);
					} else if (response.data.result.user) {
						_setSession(response.data.result.token);
						resolve(response.data.result.user);
						this.emit("onSignup", response.data.result.user);
					}
				});
		});

	/**
	 * Signs in with the provided email and password.
	 */
	signInWithEmailAndPassword = (email: string, password: string) =>
		new Promise((resolve, reject) => {
			axios
				.post(`${process.env.VITE_APP_API_BASE_URL}Users/login`, {
					email,
					password,
				})
				.then((response: AxiosResponse<IUserLoginResponse>) => {
					if (response.data.succeeded) {
						_setSession(response.data.result.token);
						this.emit("onLogin", response.data.result.user);
						resolve(response.data.result.user);
					} else {
						const errors = [
							{ type: "email", message: response.data.errors[0] },
						] as unknown;
						reject(errors);
					}
				});
		});

	googleSignIn = (user, isSignup = false) =>
		new Promise((resolve, reject) => {
			axios
				.post(`${process.env.VITE_APP_API_BASE_URL}Users/google-login`, user)
				.then((response: AxiosResponse<IUserLoginResponse>) => {
					if (response.data.succeeded) {
						_setSession(response.data.result.token);
						this.emit("onLogin", response.data.result.user);
						const user = response.data as unknown as IUserSignupResponse;
						pushToDatalayer(user.result.user.id, isSignup);
						resolve(response.data.result.user);
					} else {
						const errors = [
							{ type: "email", message: response.data.errors[0] },
						] as unknown;
						reject(errors);
					}
				});
		});

	facebookSignIn = (user, isSignup = false) =>
		new Promise((resolve, reject) => {
			axios
				.post(`${process.env.VITE_APP_API_BASE_URL}Users/facebook-login`, user)
				.then((response: AxiosResponse<IUserLoginResponse>) => {
					if (response.data.succeeded) {
						_setSession(response.data.result.token);
						this.emit("onLogin", response.data.result.user);
						const user = response.data as unknown as IUserSignupResponse;
						pushToDatalayer(user.result.user.id, isSignup);
						resolve(response.data.result.user);
					} else {
						const errors = [
							{ type: "email", message: response.data.errors[0] },
						] as unknown;
						reject(errors);
					}
				});
		});

	/**
	 * Signs in with the provided email and password.
	 */
	forgotPassword = (email: string) =>
		new Promise((resolve, reject) => {
			axios
				.post(`${process.env.VITE_APP_API_BASE_URL}Users/forgot-password`, {
					email,
				})
				.then((response: AxiosResponse<IForgotPasswordResponse>) => {
					if (response.data) {
						resolve(response.data);
					} else if (response.data.errors) {
						const errors = [
							{ type: "email", message: response.data.errors[0] },
						] as unknown;
						reject(errors);
					} else {
						const cError = [
							{
								type: "email",
								message: "Email sending failed. Please enter correct email.",
							},
						];
						reject(cError);
					}
				});
		});

	setNewPasswordByToken = (email: string, token: string, password: string) =>
		new Promise((resolve, reject) => {
			axios
				.post(
					`${process.env.VITE_APP_API_BASE_URL}Users/set-password-by-token`,
					{
						email,
						token,
						password,
					}
				)
				.then((response: AxiosResponse<IForgotPasswordResponse>) => {
					if (response.data) {
						resolve(response.data);
					} else {
						const cError = [
							{
								type: "email",
								message: "Email sending failed. Please enter correct email.",
							},
						];
						reject(cError);
					}
				});
		});

	/**
	 * Signs in with the provided provider.
	 */
	signInWithToken = () =>
		new Promise<UserType>((resolve, reject) => {
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}Users/token-login`, {
					data: {
						access_token: getAccessToken(),
					},
				})
				.then((response: AxiosResponse<IUserLoginResponse>) => {
					if (response.data.succeeded) {
						_setSession(response.data.result.token);

						let data = response.data.result.user as UserType;
						if (data.data.photoUrl) {
							data.data.photoUrl = `${process.env.VITE_APP_API_BASE_URL}Users/user-images/${data.data.photoUrl}`;
						}

						this.emit("onLogin");
						resolve(data as UserType);
					} else {
						this.logout();
						reject(new Error("Failed to login with token."));
					}
				})
				.catch(() => {
					this.logout();
					reject(new Error("Failed to login with token."));
				});
		});

	checkIfEmailExists = (email: string) =>
		new Promise<UserType>((resolve, reject) => {
			const emailErrors = [
				{ type: "email", message: "Email is already taken" },
			];
			const apiErrors = [
				{
					type: "service_unavailable",
					message:
						"Our registration service is temporarily unavailable. Please try again in a few minutes.",
				},
			] as unknown;

			axios
				.get(
					`${process.env.VITE_APP_API_BASE_URL
					}Users/email-exists/?email=${encodeURIComponent(email)}`
				)
				.then((response: AxiosResponse<any>) => {
					if (response.data.succeeded) {
						var result = response.data.result.id;
						if (result) {
							reject(emailErrors);
						} else {
							resolve(response.data.result as UserType);
						}
					} else {
						reject(emailErrors);
					}
				})
				.catch(() => {
					reject(apiErrors);
				});
		});

	deactivateUser = () =>
		new Promise<any>((resolve, reject) => {
			_setAxiosSession();
			axios
				.get(`${process.env.VITE_APP_API_BASE_URL}Users/deactivate-user`)
				.then((response: AxiosResponse<any>) => {
					resolve(response.data);
				})
				.catch((error) => {
					resolve(error);
				});
		});

	/**
	 * Updates the user data.
	 */
	updateUserData = (user: PartialDeep<UserType>) =>
		axios.post(jwtServiceConfig.updateUser, {
			user,
		});

	/**
	 * Signs out the user.
	 */
	logout = () => {
		_setSession(null);
		this.emit("onLogout", "Logged out");
	};

	partialLogout = () => {
		_setSession(null);
	};

	clearHistory = () => {
		history.pushState(null, null, location.href);
		window.onpopstate = function (event) {
			history.go(1);
		};
	};
}

/**
 * Sets the session by storing the access token in the local storage and setting the default authorization header.
 */
function _setSession(access_token: string | null) {
	if (access_token) {
		setAccessToken(access_token);
		axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
	} else {
		removeAccessToken();
		delete axios.defaults.headers.common.Authorization;
	}
}

export function _setAxiosSession() {
	const access_token = localStorage.getItem("jwt_access_token");
	if (access_token) {
		setAccessToken(access_token);
		axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
	} else {
		removeAccessToken();
		delete axios.defaults.headers.common.Authorization;
	}
}

/**
 * Checks if the access token is valid.
 */
function isAuthTokenValid(access_token: string) {
	if (!access_token) {
		return false;
	}
	const decoded = jwtDecode<JwtPayload>(access_token);
	const currentTime = Date.now() / 1000;

	if (decoded.exp < currentTime) {
		// eslint-disable-next-line no-console
		console.warn("access token expired");
		return false;
	}

	return true;
}

/**
 * Gets the access token from the local storage.
 */
function getAccessToken() {
	return window.localStorage.getItem("jwt_access_token");
}

/**
 * Sets the access token in the local storage.
 */
function setAccessToken(access_token: string) {
	return window.localStorage.setItem("jwt_access_token", access_token);
}

/**
 * Removes the access token from the local storage.
 */
function removeAccessToken() {
	return window.localStorage.removeItem("jwt_access_token");
}

function pushToDatalayer(userId, isSignup) {
	//@ts-ignore
	window.dataLayer = window.dataLayer || [];

	if (isSignup) {
		//@ts-ignore
		dataLayer.push({
			event: "sign-up",
			method: "google", // or 'email'
			user_id: userId,
		});
	} else {
		//@ts-ignore
		dataLayer.push({
			event: "login",
			method: "google", // or 'email' if they used email/pass
			user_id: userId,
		});
	}
}

const instance = new JwtService();

export default instance;
