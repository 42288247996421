import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import _ from "@lodash";
import { useEffect, useState } from "react";
import { Alert, Button, Link, Stack } from "@mui/material";
import FuseLoading from "@fuse/core/FuseLoading";
import paymentService from "@fuse/services/paymentService";
import IRegistrationCompleteType from "../../types/registration.type";
import parse from "html-react-parser";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FuseUtils from "@fuse/utils";

function PetRegistrationComplete() {
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const petId = queryParams.get("id");
	const sessionId = queryParams.get("session_id");

	const [customError, setCustomError] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		//	JwtService.init();
		//	handleUnAuthorize();

		paymentService
			.getRegistrationComplete(sessionId)
			.then((res) => {
				if (res) {
					const data = res as IRegistrationCompleteType;
					handleDataLayerPush(data);
				}
			})
			.catch((res) => {
				console.log(res);
			});
	}, []);

	function handleUnAuthorize() {
		if (!localStorage.getItem("jwt_access_token")) {
			navigate("/sign-in");
		}
	}

	function handleDataLayerPush(data: IRegistrationCompleteType) {
		const userData = {
			email: data.customerEmail,
			phone_number: FuseUtils.formatPhoneNumber(data.phoneNumber),
			address: {
				first_name: data.firstName,
				last_name: data.lastName,
				street: data.customerAddress.line1,
				city: data.customerAddress.city,
				region: data.customerAddress.state,
				postal_code: data.customerAddress.zipCode,
				country: data.customerAddress.country,
			},
		};

		//@ts-ignore
		window.dataLayer = window.dataLayer || [];
		//@ts-ignore
		window.dataLayer.push({
			event: "purchase",
			currency: data.currency,
			value: data.orderTotal / 100,
			transaction_id: data.paymentId,
			coupon: data.coupon,
			tax: data.tax,
			user_data: userData,
			new_customer: true,
		});

		//@ts-ignore
		window.dataLayer.push({
			event: "registrationComplete",
		});
	}

	return (
		<div className="w-full sm:h-auto sm:w-auto md:flex md:h-full md:justify-end">
			<div className="flex flex-col min-w-0 md:min-h-screen justify-center bg-white w-full">
				<div className="h-full flex-column justify-center md:flex md:h-full mx-auto px-16 sm:px-60 md:px-80 py-8 w-full">
					<div className="mx-auto sm:mx-0">
						{customError && (
							<Stack className="pb-12" sx={{ width: "100%" }} spacing={2}>
								<Alert variant="filled" severity="error" onClose={() => { }}>
									<div className="text-12px font-400 leading-14px">
										{parse(customError)}
									</div>
								</Alert>
							</Stack>
						)}

						{loading && <FuseSplashScreen />}
						<div className="flex flex-row justify-center my-40">
							<div className="flex items-start justify-end  relative cursor-pointer">
								<Link
									className="mt-4 w-full text-16 text-center transition-colors duration-200"
									href={"/"}
									role="button"
									color="inherit"
								>
									<img
										className="h-40px w-[110px] flex mx-auto"
										src="assets/img/checkout-logo.svg"
										alt="logo"
									/>
								</Link>
							</div>
						</div>

						<Typography className="font-quicksand sm:text-32px text-28px font-700 sm:leading-38px leading-32px  text-black tracking-tight text-center">
							Thank You
						</Typography>

						<Typography className="font-robotoslab mt-24 sm:text-16px text-14px font-700 text-black sm:leading-18px leading-16px px-10 text-center">
							Your pet will thank you too!
						</Typography>
						<Typography className="font-robotoslab mt-24 sm:text-16px text-14px font-400 text-black sm:leading-22px leading-16px px-10 text-center">
							Take full advantage of our service today. Upload your pet's photo,
							share their information, add your vet, and set up backup contacts.
							If your pet goes missing, rest assured you have a support system
							ready to help bring them back home.
						</Typography>

						<div className="grid grid-cols-1 mt-40 mb-32">
							<Button
								color="primary"
								className="mt-4 tracking-wide capitalize sm:h-54px w-[200px] mx-auto h-44px rounded-full bg-peeva-blue border hover:bg-peeva-blue-110 text-white transition-colors duration-200 px-24 sm:px-32 py-12 sm:py-16 text-14px sm:text-16px font-700 sm:leading-10px leading-19px"
								aria-label="Sign in"
								type="submit"
								size="small"
								onClick={() => navigate("/")}
								role="button"
							>
								Go to dashboard
							</Button>

							<Card className="flex flex-col w-1/2 mx-auto mt-72 p-12">
								<CardContent>
									<Typography
										variant="h5"
										component="div"
										className=" font-robotoslab text-center text-peeva-black text-22px font-700 leading-22px"
									>
										Protect Your Pet Even More with Pet Insurance
									</Typography>
									<Typography className="font-robotoslab text-center text-peeva-black text-19px font-400 leading-22px my-10">
										Save up to 90% on vet bills with pet insurance, get a free
										quote today from Lemonade
									</Typography>
									<div className="flex w-full justify-center">
										<Button
											color="primary"
											className="mt-4 w-[160px] mx-auto h-46px rounded-full bg-peeva-yellow border hover:bg-peeva-yellow-110 text-white transition-colors duration-200 px-24 sm:px-32 py-16 text-16px font-500 leading-19px"
											aria-label="Learn More"
											type="submit"
											size="small"
											href="https://go.lemonade.com/visit/?bta=36523&nci=5690"
											target="_blank"
											role="button"
										>
											Learn More
										</Button>
									</div>
								</CardContent>
								<CardActions className="w-full flex justify-center pt-8">
									<Typography
										component="div"
										className="font-robotoslab text-center text-peeva-black text-19px font-600 leading-22px"
									>
										Insurance by
									</Typography>
									<img
										className="w-[80px]"
										src="assets/img/lemonade_logo.svg"
										alt="logo"
									/>
								</CardActions>
							</Card>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="flex justify-between flex-col h-full md:h-full md:pb-0 md:w-1/2 max-h-screen overflow-hidden pb-0 px-0 relative sm:h-auto sm:shadow sm:w-auto">
				<img
					className="w-full flex mx-auto"
					src="assets/img/signupIcon.svg"
					alt="logo"
				/>
			</div> */}
		</div>
	);
}

export default PetRegistrationComplete;
