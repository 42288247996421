import FusePageSimple from "@fuse/core/FusePageSimple";
import _ from "@lodash";
import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import CardHeading from "@fuse/core/CardHeading/CardHeading";
import Link from '@mui/material/Link';

const Root = styled(FusePageSimple)(({ theme }) => ({
	"& .FusePageSimple-header": {
		backgroundColor: "#f1f5f9",
		boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
	},
}));

function PerksApp() {
	const navigate = useNavigate();

	return (
		<div className="w-full p-[20px]">
			<div className="flex flex-col sm:flex-row my-12 gap-[20px]">
				<Card className="flex flex-col w-full p-[28px] overflow-hidden gap-[20px]">
					<div className="flex w-full flex-wrap gap-[20px]">
						<div className="flex w-full">
							<motion.div
								initial={{ x: 20, opacity: 0 }}
								animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
							>
								<CardHeading
									heading="Member Perks"
									customClassHeading="font-quicksand font-700 text-18px sm:text-22px leading-22px sm:leading-26px text-peeva-black"
									subHeading="Caring for your pet is your top priority, and it's ours too. That's why we partner only with trusted providers to bring you the best products and services curated by our executive team. Enjoy exclusive discounts on essentials designed to keep your pet healthy, happy and safe-no gimmicks, just real value."
									customClassSubHeading="text-16px font-400 tracking-tight leading-22px"
								/>
							</motion.div>
						</div>
					</div>
					<div className="flex w-full flex-col flex-wrap gap-[20px] mt-12">
						<div className="flex w-full flex-col sm:flex-row gap-28">
							<motion.div
								className="flex flex-col justify-between"
								initial={{ x: 20, opacity: 0 }}
								animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
							>
								<Link href="https://vetster.com/en-us/plus/peeva" target="_blank"
								>
									<img
										className="flex mx-auto"
										src="assets/perks/vetster.svg"
										alt="Member Perks"
									/></Link>
								<Typography className="flex w-full items-center justify-center text-20px text-peeva-black ml-10">
									20% off
								</Typography>
							</motion.div>

							<motion.div
								className="flex flex-col justify-between"
								initial={{ x: 20, opacity: 0 }}
								animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
							>
								<Link href="https://www.pntrac.com/t/2-559743-345797-219166" target="_blank"
								>
									<img
										className="flex mx-auto"
										src="assets/perks/petmeds.svg"
										alt="Member Perks"
									/></Link>
								<Typography className="flex w-full items-center justify-center text-20px text-peeva-black ml-10">
									20% off for new customers
								</Typography>
							</motion.div>

							<motion.div
								className="flex flex-col justify-between"
								initial={{ x: 20, opacity: 0 }}
								animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
							>
								<Link href="https://click.linksynergy.com/link?id=nvEPeYyknKk&offerid=785949.459043833528465&type=2&murl=https%3a%2f%2ftractive.com%2fen-us%2fpd%2fgps-tracker-dog%3fshopCountry%3dUS" target="_blank"
								>
									<img
										className="flex mx-auto"
										src="assets/perks/tractive.png"
										alt="Member Perks"
									/>
									<img width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=nvEPeYyknKk&bids=785949.459043833528465&type=2&subid=0"
									/></Link>
								<Typography className="flex w-full items-center justify-center text-20px text-peeva-black ml-10">
									30% off GPS collars
								</Typography>

							</motion.div>

							<motion.div
								className="flex flex-col justify-between"
								initial={{ x: 20, opacity: 0 }}
								animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
							>
								<Link href="https://go.lemonade.com/visit/?bta=36523&nci=5690" target="_blank"
								>
									<img
										className="flex mx-auto w-[350px] h-[100px] px-[1.5em]"
										src="assets/perks/lemonade.svg"
										alt="Member Perks"
									/>
								</Link>
								<Typography className="flex w-full items-center justify-center text-20px text-peeva-black ml-10">
									Affordable pet insurance
								</Typography>
							</motion.div>
						</div>
					</div>
				</Card>
			</div>


		</div>
	);
}

export default PerksApp;
