import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import _ from "@lodash";
import { useEffect, useMemo, useState } from "react";
import utilityService from "@fuse/services/utilityService";
import { IApiResponse } from "@fuse/models/user.models";
import { peevaBlue } from "@fuse/colors";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import {
	Alert,
	Checkbox,
	FormControlLabel,
	FormGroup,
	IconButton,
	InputAdornment,
	Stack,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import jwtService from "../services/jwtService";
import { UserType } from "app/store/user";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import HTMLReactParser from "html-react-parser";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import FieldLabel from "@fuse/core/FieldLabel/FieldLabel";
// import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";

//FIXME: Replace with created components
const FieldLabel = ({ children, customClass = null }) => (
	<label
		className={
			customClass
				? customClass
				: "flex w-full sm:text-[16px] sm:leading-[24px] text-[14px] leading-[17.5px] font-700 mb-4"
		}
	>
		{children}
	</label>
);
const CustomPrimaryButton = ({ children, onClick }) => (
	<Button
		variant="contained"
		color="secondary"
		className="sm:h-56px h-48px rounded-[32px] bg-peeva-yellow border hover:bg-peeva-yellow-110 transition-colors duration-200 py-10 sm:text-16px font-700 sm:leading-18px text-14px leading-16px text-peeva-black"
		aria-label={children}
		type="submit"
		size="large"
		onClick={onClick}
	>
		{children}
	</Button>
);

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	email: yup
		.string()
		.email("You must enter a valid email")
		.max(255, "Email can't be more than 255 characters long")
		.required("You must enter a email"),
	password: yup
		.string()
		.max(255, "Password can't be more than 255 characters long")
		.required("Please enter your password."),
});

/**
 * The sign up page.
 */
function SignInHome() {
	const fbClientId = useMemo(() => process.env.VITE_APP_FACEBOOK_ID, [process.env.VITE_APP_FACEBOOK_ID]);

	const [loading, setLoading] = useState(false);
	const [clientUrl, setClientUrl] = useState("");
	const [showPassword, setShowPassword] = useState(true);
	const [customError, setCustomError] = useState("");
	const [rememberMe, setRememberMe] = useState(true);

	let defaultValues = {
		email: "",
		password: "",
	};

	useEffect(() => {
		getClientSettings();
		let usernameOrEmail = localStorage.getItem("usernameOrEmail");
		if (usernameOrEmail) {
			defaultValues.email = usernameOrEmail;
		}
	}, []);

	const { control, formState, trigger, handleSubmit, setError, clearErrors } =
		useForm({
			mode: "onChange",
			defaultValues,
			resolver: yupResolver(schema),
		});

	const { isValid, dirtyFields, errors } = formState;

	async function getClientSettings() {
		const res =
			(await utilityService.getExistingClientSettings()) as IApiResponse;
		if (res.succeeded) {
			setClientUrl(res.result.clientSiteBase);
		}
	}

	function onSubmit({ email, password }: typeof defaultValues) {
		setCustomError("");
		jwtService
			.signInWithEmailAndPassword(email, password)
			.then((user: UserType) => {
				if (rememberMe) {
					localStorage.setItem("usernameOrEmail", email);
				} else {
					localStorage.removeItem("usernameOrEmail");
				}

				//@ts-ignore
				window.dataLayer = window.dataLayer || [];
				//@ts-ignore
				dataLayer.push({
					event: "login",
					method: "email", // or 'email' if they used email/pass
					user_id: user.id,
				});
			})
			.catch(
				(
					_errors: {
						type: "email" | "password" | `root.${string}` | "root";
						message: string;
					}[]
				) => {
					setLoading(false);
					setCustomError(_errors[0].message);

					setTimeout(() => {
						setCustomError("");
					}, 8000);
				}
			);
	}

	function hideLoading() {
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const handleRememberMe = () => {
		setRememberMe((prevState) => !prevState);
	};

	const login = useGoogleLogin({
		onSuccess: (tokenResponse) => {
			setLoading(true);
			const { access_token } = tokenResponse;
			getUserInfo(access_token);
		},
		onError: (errorResponse) => {
			setLoading(false);
			console.log("Login Failed:", errorResponse);
		},
		flow: "implicit",
	});

	const responseFacebook = async (response) => {
		console.log('facebook response', response);
		jwtService.facebookSignIn(response).then((user: UserType) => {
			setLoading(false);

			if (rememberMe) {
				localStorage.setItem("usernameOrEmail", user.data.email);
			} else {
				localStorage.removeItem("usernameOrEmail");
			}
		})
			.catch(
				(
					_errors: {
						type: "email" | "password" | `root.${string}` | "root";
						message: string;
					}[]
				) => {
					setLoading(false);
					setCustomError(_errors[0].message);

					setTimeout(() => {
						setCustomError("");
					}, 8000);
				}
			);
	}


	const getUserInfo = async (token) => {
		try {
			const response = await fetch(
				"https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setLoading(true);
			const data = await response.json();
			const userData = {
				email: data?.email,
				family: data?.family_name,
				given: data?.given_name,
				hd: data?.hd,
				id: data?.id,
				name: data?.name,
				picture: data?.picture,
				Verified: data?.verified_email,
			};

			if (data) {
				jwtService
					.googleSignIn(userData)
					.then((user: UserType) => {
						setLoading(false);

						if (rememberMe) {
							localStorage.setItem("usernameOrEmail", user.data.email);
						} else {
							localStorage.removeItem("usernameOrEmail");
						}
					})
					.catch(
						(
							_errors: {
								type: "email" | "password" | `root.${string}` | "root";
								message: string;
							}[]
						) => {
							setLoading(false);
							setCustomError(_errors[0].message);

							setTimeout(() => {
								setCustomError("");
							}, 8000);
						}
					);
			}
		} catch (error) {
			console.error("Error fetching user info:", error);
		}
	};

	return (
		<>
			<div className="flex flex-col bg-white justify-center w-full h-full md:overflow-hidden hide-scrollbar relative">
				{loading && <FuseSplashScreen />}
				<div className="h-full w-full flex md:flex-row flex-col overflow-hidden hide-scrollbar">
					<form
						name="signinForm"
						noValidate
						className="justify-center md:basis-6/12 flex flex-col w-full h-full md:p-[40px_80px_20px_80px] p-[80px_20px_80px_20px] md:gap-[30px] gap-[20px] items-center relative"
						id="signuphome-form"
						onSubmit={handleSubmit(onSubmit)}
					>
						<img
							className="h-[40px] w-[110.86px]"
							src="assets/img/checkout-logo.svg"
							alt="logo"
						/>
						<div className="flex flex-col w-full items-center">
							{customError && (
								<Stack className="pb-12" sx={{ width: "100%" }} spacing={2}>
									<Alert
										variant="filled"
										severity="error"
										onClose={() => {
											setCustomError("");
										}}
									>
										<p>{HTMLReactParser(customError)}</p>
									</Alert>
								</Stack>
							)}

							<div className="flex flex-col gap-[8px] w-full">
								<FieldLabel>Email</FieldLabel>
								<Controller
									name="email"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-20 bg-white rounded-24 h-[44px] md:h-46px text-14px md:text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
											type="email"
											autoFocus
											placeholder="email@provider.com"
											error={!!errors.email}
											helperText={errors?.email?.message}
											required
											fullWidth
											variant="outlined"
											inputProps={{ maxLength: 255 }}
											sx={{ border: "2px solid black" }}
										/>
									)}
								/>
							</div>
							<div className="flex flex-col gap-[8px] w-full">
								<FieldLabel>Password</FieldLabel>
								<Controller
									name="password"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-20 bg-white rounded-24 h-[44px] md:h-46px text-14px md:text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
											type={showPassword ? "password" : "text"}
											error={!!errors.password}
											helperText={errors?.password?.message}
											placeholder="Enter your password"
											variant="outlined"
											required
											autoComplete="off"
											name="password"
											fullWidth
											sx={{ border: "2px solid black" }}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleShowPassword}
															edge="end"
														>
															{showPassword ? (
																<VisibilityOff />
															) : (
																<Visibility />
															)}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									)}
								/>
							</div>
							<Link
								className="font-700 text-[16px] leading-[22.4px] text-peeva-blue"
								to="/forgot-password"
							>
								Forgot Password
							</Link>
						</div>
						<div className="flex justify-start w-full">
							<Typography component="div" className="text-black">
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												checked={rememberMe}
												sx={{
													color: peevaBlue.DEFAULT,
													"&.Mui-checked": {
														color: peevaBlue.DEFAULT,
													},
												}}
												onClick={handleRememberMe}
											/>
										}
										label={
											<Typography className="md:!text-16px text-14px font-400 md:leading-[22.4px] leading-18px">
												Remember Me
											</Typography>
										}
									/>
								</FormGroup>
							</Typography>
						</div>
						<div className="flex">
							<CustomPrimaryButton
								onClick={() => {
									trigger();
								}}
							>
								Log In
							</CustomPrimaryButton>
						</div>
						<div className="flex justify-center items-baseline">
							<Typography className="text-peeva-black md:text-16px text-14px font-700 md:leading-22px leading-22px">
								Don't have an account?
							</Typography>
							<Link
								className="ml-4 text-blue !no-underline md:text-16px text-14px font-700 md:leading-22px leading-22px"
								to="/sign-up"
							>
								Sign up
							</Link>
						</div>
						<div className="mt-32 flex flex-col sm:flex-row items-center sm:space-x-16 w-full sm:w-auto">
							<div className="py-4 flex sm:w-auto w-full">
								<Button
									variant="contained"
									className="w-full sm:h-58px h-58px sm:w-auto rounded-[16px] bg-white border-solid border-2 border-grey hover:bg-white-110 transition-colors duration-200 py-10 sm:text-16px font-700 sm:leading-18px text-14px leading-16px text-peeva-black"
									aria-label="Sign up with Google"
									type="button"
									size="large"
									onClick={() => login()}
								>
									<img
										className="h-[16px] w-[16px]"
										src="assets/img/Googlelogo.svg"
										alt="logo"
									/>
									&nbsp;Sign in with Google
								</Button>
							</div>
							{
								fbClientId && (<div className="py-4 flex sm:w-auto w-full">
									<FacebookLogin
										appId={fbClientId}
										autoLoad={false}
										fields="id,name,email,first_name,last_name,gender,location"
										callback={responseFacebook}
										render={renderProps => (
											<Button
												variant="contained" className="w-full py-28 sm:w-auto sm:h-58px h-58px rounded-[16px] bg-white border-solid border-2 border-grey hover:bg-white-110 transition-colors duration-200 sm:text-16px font-700 sm:leading-18px text-14px leading-16px text-peeva-black px-20"
												onClick={renderProps.onClick}>
												<FacebookRoundedIcon className="h-[25px] w-[25px] text-peeva-blue mr-3" />
												Sign in with Facebook</Button>
										)}
									/>
								</div>)
							}

						</div>
					</form>

					<div className="md:max-h-[100vh] flex justify-between flex-col h-full w-full md:pb-0 md:w-1/2 overflow-hidden pb-0 px-0 sm:shadow bg-no-repeat bg-top object-fill md:bg-center bg-contain md:bg-cover">
						<img className="object-fill" src="assets/img/signupIcon.svg" />
					</div>
				</div>
			</div>
		</>
	);
}

export default SignInHome;
