import Typography from "@mui/material/Typography";
import { memo } from "react";
import Paper from "@mui/material/Paper";
import { motion } from "framer-motion";
import { IPetModel } from "@fuse/models";
import { useNavigate } from "react-router";
import { Tooltip } from "@mui/material";

/**
 * The TeamMembersWidget widget.
 */
function CustomerPets(props) {
	const pets = props.pets as Array<IPetModel>;
	const navigate = useNavigate();

	const container = {
		show: {
			transition: {
				staggerChildren: 0.05,
			},
		},
	};

	const item = {
		hidden: { opacity: 0, y: 20 },
		show: { opacity: 1, y: 0 },
	};

	function getPetImage(pet: IPetModel) {
		if (pet.image) {
			return `${process.env.VITE_APP_API_BASE_URL}Pet/pet-images/${pet.image}`;
		} else if (pet.species?.name) {
			return `assets/icons/pets/${pet.species.name.toLowerCase()}.png`;
		} else {
			return `assets/icons/pets/dog.png`;
		}
	}


	function handleEdit(pet: IPetModel) {
		if (pet.isActive) {
			navigate(`/client/pet/update/${pet.id}`);
		} else {
			navigate(`/client/pet/checkout/${pet.id}`);
		}
	}
	function checkIfPetIsNotActive(pet: IPetModel) {
		return !pet.isActive || !isChipActive(pet) || pet.subscriptionExpired;
	}

	function isChipActive(pet: IPetModel) {
		return pet.chips && pet.chips.every(f => f.isActive);
	}

	return (
		<>
			<div className="flex gap-[20px] overflow-x-auto overflow-y-hidden">

				{pets.map((pet) => (
					<div className="relative" key={pet.id}>
						{
							checkIfPetIsNotActive(pet) && (
								<div className="w-[30px] h-[30px] absolute top-0 right-[-15px] z-10">
									<Tooltip title={pet.subscriptionExpired ? 'Chip Deactivated' : 'Registration Incomplete'}>
										<img className="w-full h-full object-contain" src="assets/img/warning.png" />
									</Tooltip>
								</div>
							)
						}
						<div
							className="flex flex-col items-center justify-center gap-[12px] cursor-pointer hover:scale-[101%] sm:w-[91px] w-[75px] py-10 relative"
							key={pet.id}
							onClick={() => handleEdit(pet)}
						>
							<div className="sm:w-[91px] sm:h-[91px] w-[75px] h-[75px] rounded-[8px] overflow-hidden border">
								<img
									className={`w-full h-full object-cover p-4 border ${pet.image ? "" : "p-24"
										}`}
									src={getPetImage(pet)}
								/>
							</div>
							<h3 className="font-700 text-12px md:text-[14px] leading-17px md:leading-20px whitespace-nowrap truncate w-full text-center">
								{pet.name}
							</h3>
						</div>
					</div>
				))}
			</div>
		</>
	);
}

export default memo(CustomerPets);
