import { combineReducers } from '@reduxjs/toolkit';
import dialog from './dialogSlice';
import message from './messageSlice';
import navbar from './navbarSlice';
import navigation from './navigationSlice';
import settings from './settingsSlice';
import { peevaSettingsSlice } from './peevaSettings';

/**
 * The Fuse reducer.
 */

const reducer = combineReducers({
	navigation,
	settings,
	navbar,
	message,
	dialog,
	peevaSettings: peevaSettingsSlice.reducer
});

export default reducer;
