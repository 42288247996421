import FuseUtils from "@fuse/utils/FuseUtils";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
	IApiResponse,
	IForgotPasswordResponse,
} from "@fuse/models/user.models";
import { IPetModel } from "@fuse/models";

class PaymentService {
	getCheckoutUrl = (petId: string, petFlow: boolean = false, isTagSelected: boolean = false) =>
		new Promise((resolve, reject) => {
			axios
				.get(
					`${process.env.VITE_APP_API_BASE_URL}checkout/stripe-subscription/${petId}?petFlow=${petFlow}&isTagSelected=${isTagSelected}`
				)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data) {
						resolve(response.data);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});

	getRegistrationComplete = (sessionId: string) =>
		new Promise((resolve, reject) => {
			axios
				.get(
					`${process.env.VITE_APP_API_BASE_URL}payment/complete/${sessionId}`
				)
				.then((response: AxiosResponse<IApiResponse>) => {
					if (response.data.succeeded) {
						resolve(response.data.result);
					} else if (response.data.errors) {
						reject(response.data.errors);
					} else {
						reject(response.data);
					}
				});
		});
}

const instance = new PaymentService();

export default instance;
