import { memo, useEffect, useState } from "react";
import { IVetModel } from "@fuse/models";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useNavigate } from "react-router";
import vetService from "@fuse/services/vetService";
export interface IImageMap {
	placeId: string;
	url: string;
	address?: string;
	phone?: string
}


function CustomerVets({ vets }) {
	const navigate = useNavigate();
	const [imageMap, setImageMap] = useState(Array<IImageMap>);
	const { placesService } = usePlacesService({
		apiKey: process.env.VITE_APP_GOOGLE,
	});
	const [fVets, setFVets] = useState<Array<IVetModel>>(vets);

	useEffect(() => {
		if (vets?.length > 0) {
			processSyncVets();
			getVets();
		}
	}, [vets]);

	async function processSyncVets() {
		vets
			.filter((f) => f.googlePlaceId)
			.forEach((element) => {
				if (element.needUpdate) {
					placesService.getDetails(
						{ placeId: element.googlePlaceId },
						async (result: any) => {
							let item = {} as any;
							item.reference = result.reference;
							item.place_id = result.place_id;
							item.placeDetail = result;
							if (result.photos) {
								const url = result.photos[0].getUrl({ maxHeight: 300 });
								item.imageUrl = url;
							}
							item.name = result.name;
							item.address1 = result.formatted_address;
							item.phoneNumber = result.formatted_phone_number;
							item.rating = +result.rating;
							item.reviews = result.reviews?.length || 0;
							item.url = result.website;


							if (result.types && result.types.includes("veterinary_care")) {
								item.practiceType = "Veterinary Care";
							}

							const obj = {
								name: item.name,
								googlePlaceId: result.place_id,
								isActive: item.active,
								phoneNumber: item.phoneNumber,
								address1: item.address1,
								url: item.url,
								imageUrl: item.imageUrl,
								placeDetail: item.placeDetail,
							};

							try {
								const _ = (await vetService.addSite(obj)) as any;
							} catch (e) {
								console.error(e);
							}
						}
					);
				} else {

				}
			});
	}

	async function getVets() {
		const userVets = await vetService.getUserVets() as Array<IVetModel>;
		setFVets(userVets);
	}

	async function handleAddVet(detail: any) {
		let siteId = null;
		const obj = {
			name: detail.name,
			googlePlaceId: detail.place_id,
			isActive: detail.active,
			phoneNumber: detail.phoneNumber,
			address1: detail.address1,
			url: detail.url,
			imageUrl: detail.imageUrl,
			placeDetail: detail.placeDetail,
		};

		try {
			const addSite = (await vetService.addSite(obj)) as any;
		} catch (e) {
			console.error(e);
		}
	}

	function getVetImage(vet: IVetModel) {
		if (vet?.googlePlaceId) {
			return (
				imageMap.find((f) => f.placeId === vet.googlePlaceId)?.url ||
				"assets/img/logo.png"
			);
		} else {
			return "assets/img/logo.png";
		}
	}

	return (
		<div className="flex gap-[20px] overflow-x-auto overflow-y-hidden">
			{vets.map((vet) => (
				<div className="flex flex-col items-center justify-center gap-[12px] cursor-pointer hover:scale-[101%] sm:w-[91px] w-[75px] py-10" key={vet.id} onClick={() => navigate(`/client/vet-info/profile/${vet.id}`)}>
					<div className="sm:w-[91px] sm:h-[91px] w-[75px] h-[75px] rounded-[8px] overflow-hidden border">
						<img className={`w-full h-full object-cover p-4 border `} src={vet.logo} />
					</div>
					<h3 className="font-700 text-12px md:text-[14px] leading-17px md:leading-20px whitespace-nowrap truncate w-full text-center">{vet.name}</h3>
				</div>
			))}
		</div>
	);
}

export default memo(CustomerVets);
