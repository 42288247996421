import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface PetChipConfirmationDialogProps {
  open: boolean;
  handleClose: () => void;
  handleLPANavigate: () => void;
  dialogText?: string;
}

const PetChipConfirmationDialog: React.FC<PetChipConfirmationDialogProps> = ({ open, handleClose, handleLPANavigate: handleDelete, dialogText }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>No microchip?</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText || 'Peeva is a universal microchip registry, where any brand of microchip can be enrolled in our centralized pet recovery database. However, we have recently expanded the ability to utilize our lost pet alert service for unchipped pets. Please confirm which action you are looking to do:'} </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className='font-600' onClick={handleClose}>Register Pet Microchip</Button>
        <Button variant="contained" className='font-600' onClick={handleDelete}>
          Send Lost Pet Alert
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PetChipConfirmationDialog;
