import _ from "@lodash";
import { useLayoutEffect, useState } from "react";
import FuseLoading from "@fuse/core/FuseLoading";
import { useNavigate, useParams } from "react-router-dom";
import petService from "@fuse/services/petService";
import { showMessage } from "app/store/fuse/messageSlice";
import { useDispatch } from "react-redux";

function ShortUrlPage() {
	const dispatch = useDispatch();	
	const navigate = useNavigate();
	const [loading, setLoading]	= useState(false);
	const param = useParams();
	const id = param.id;
	
	useLayoutEffect(() => {
		processShortUrl();
	}, []);

	function processShortUrl() {
		
		setLoading(true);
		petService
			.getPetByShortUrl(id)
			.then((res: any) => {
				setLoading(false);
				if (res.result) {
					navigate(`/pet-profiles/${res.result}`);
				} else {
					dispatch(
						showMessage({
							message: "Invalid Short URL",
							autoHideDuration: 60000,
							variant: "error",
						})
					);
					navigate("./sign-in");
				}
			})
			.catch((error) => {
				
			});
	}

	return (
		<div className="flex w-full h-full flex1 flex-col">
			<div className="flex flex-auto h-100 min-h-0 relative z-10 chip-lookup-wrapper">
				
			</div>
			
			{loading && <FuseLoading className="full-screen" />}
		</div>
	);
}

export default ShortUrlPage;
