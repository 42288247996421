import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { motion } from "framer-motion";
import { useState, useEffect, useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Autocomplete, Card, IconButton, Rating, TextField, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import VetMap from "../VetProfile/VetMap";
import GoBackCommon from "@fuse/core/GoBackCommon/GoBackCommon";
import VetInfoCard from "../VetInfoCard/VetInfoCard";
import vetService from "@fuse/services/vetService";
import { IApiResponse } from "@fuse/models/user.models";
import { IVetModel } from "@fuse/models";

const BootstrapAutoComplete = styled(Autocomplete)(({ theme }: any) => ({
  '&': {
    width: '100%'
  },
  '& .MuiAutocomplete-inputRoot': {
    width: '100% !important',
    borderRadius: 28,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    fontSize: 16,
    padding: '6px 16px',
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    boxShadow: 'none',
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Roboto Slab',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 28,
      borderColor: '#000',
      boxShadow: 'none',
    },
  },
  '.Mui-focused': {
    boxShadow: 'none',
  },
}));


function VetInfoCards({ vet }) {
  const [coordinates, setCoordinates] = useState<any>({});
  const [openingHours, setOpeningHours] = useState<{ day: string, time: string }[]>([]);

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };


  useEffect(() => {

    if (vet.placesJson) {
      const placeDetails = JSON.parse(vet.placesJson);

      const openingHrs = placeDetails.opening_hours?.weekday_text ?? [];
      const newOpeningHrs = openingHrs.map(hrs => ({
        day: hrs.split(" ")[0],
        time: hrs.split(" ")[1]
      }));
      setOpeningHours(newOpeningHrs);

      vet.address1 = placeDetails.formatted_address;
      setCoordinates({
        latitude: placeDetails.geometry.location.lat,
        longitude: placeDetails.geometry.location.lng,
      });
      vet.phoneNumber = placeDetails.formatted_phone_number;
      vet.rating = +placeDetails.rating;
      vet.website = placeDetails.website;
      vet.reviews = placeDetails.reviews?.length ?? 0;

      vet.imageUrl = vet.logo || "assets/img/logo.png";
      if (
        placeDetails.types &&
        placeDetails.types.includes("veterinary_care")
      )
        vet.practiceType = "Veterinary Care";
    }

  }, []);

  return (
    <>
      <Card className="w-full sm:h-full h-[154px] p-[28px]">
        <VetInfoCard detail={vet} />
      </Card>
      <motion.div variants={item} className="sm:h-full h-[698px] w-full flex sm:flex-row flex-col gap-[20px]">
        <Card className="w-full h-full p-[28px] flex flex-col gap-[20px]">
          <h2 className="font-700 sm:text-[22px] sm:leading-[26.4px] text-[18px] leading-[21.6px]">
            More Information
          </h2>
          <div className="flex sm:h-full w-full h-full flex-col gap-[4px]">
            <h3 className="font-700 sm:text-[16px] sm:leading-[22.4px] text-[14px] leading-[19.6px]">
              Business Hours
            </h3>
            {
              openingHours.map(hrs => (<div className="flex sm:flex-row gap-[20px]" key={hrs.day}>
                <div className="w-full">
                  <p className="font-400 sm:text-[16px] sm:leading-[22.4px] text-[14px] leading-[21px] text-black">
                    {hrs.day}
                  </p>
                </div>
                <div className="w-full">
                  <p className="font-400 sm:text-[16px] sm:leading-[22.4px] text-[14px] leading-[21px] text-black">
                    {hrs.time}
                  </p>
                </div>
              </div>))
            }
          </div>
          <div className="flex flex-col gap-[4px] w-full h-full">
            <h3 className="font-700 sm:text-[16px] sm:leading-[22.4px] text-[14px] leading-[19.6px] w-full h-full">
              Website:
            </h3>
            <p className="font-400 sm:text-[16px] sm:leading-[22.4px] text-[14px] leading-[21px]  text-peeva-black w-full h-full break-words">
              {vet.website}
            </p>
          </div>
        </Card>
        <Card className="w-full h-full flex flex-col">
          {coordinates.latitude && coordinates.longitude && (
            <VetMap lat={coordinates.latitude} lng={coordinates.longitude} vetName={vet.name ?? "TEST VET"} />
          )}
        </Card>
      </motion.div>
    </>
  )
}

function VetInfo() {
  const { id } = useParams();
  const [vet, setVet] = useState<IVetModel>({} as IVetModel);

  useLayoutEffect(() => {
    vetService.getSiteById(id).then((s: IApiResponse) => {
      setVet(s.result);
    });
  }, []);

  const { placesService } = usePlacesService({
    apiKey: process.env.VITE_APP_GOOGLE,
  });

  const container = {
    show: {
      transition: {
        staggerChildren: 0.06,
      },
    },
  };

  return (
    <motion.div variants={container} className="flex flex-col w-full p-[20px] gap-[20px]">
      <GoBackCommon />
      {(vet.id) && <VetInfoCards vet={vet} />}
    </motion.div>
  )
}

export default VetInfo;

