import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Card,
	Rating,
} from "@mui/material";
import vetService from "@fuse/services/vetService";
import CustomOutlinePrimaryButton from "@fuse/core/CustomOutlinePrimaryButton/CustomOutlinePrimaryButton";

function VetProfileCard({ data, service }) {
	const navigate = useNavigate();
	const [detail, setDetail] = useState({} as any);
	const [showDialog, setShowDialog] = useState(false);
	const [siteId, setSiteId] = useState(null);
	const [error, setError] = useState("");

	useLayoutEffect(() => {
		service.getDetails({ placeId: data.place_id }, (result: any) => {
			if (result) {
				let item = {} as any;
				item.reference = result.reference;
				item.place_id = result.place_id;
				item.placeDetail = result;
				if (result.photos) {
					const url = result.photos[0].getUrl({ maxHeight: 300 });
					item.imageUrl = url;
					item.logo = url;
				}
				item.name = result.name;
				item.address1 = result.formatted_address;
				item.phoneNumber = result.formatted_phone_number;
				item.rating = +result.rating;
				item.reviews = result.reviews?.length || 0;
				item.url = result.website;

				if (result.types && result.types.includes("veterinary_care"))
					item.practiceType = "Veterinary Care";

				setDetail(item);
			}
		});
	}, [data]);

	async function handleAddVet(detail: any) {
		let siteId = null;
		const obj = {
			name: detail.name,
			googlePlaceId: detail.place_id,
			isActive: detail.active,
			phoneNumber: detail.phoneNumber,
			address1: detail.address1,
			url: detail.url,
			imageUrl: detail.imageUrl,
			placeDetail: detail.placeDetail,
		};

		try {
			const site = (await vetService.getSiteByGooglePlaceId(
				detail.place_id, obj
			)) as any;
			if (site?.id) {
				siteId = site.id;
			} else {
				const addSite = (await vetService.addSite(obj)) as any;
				siteId = addSite.id;
			}
			navigate("/client/add-vet-on-pet/" + siteId);
			return;
		} catch (e) {
			console.error(e);
		}

		if (siteId) {
			setSiteId(siteId);
			setShowDialog(true);
		} else {
			setError("Unable to create site for provided place");
		}
	}

	return (
		<>
			<Card className="p-[28px] flex sm:flex-row flex-col gap-[20px] w-full">
				<div className="flex gap-[12px] w-full">
					<div className="flex h-[95px] w-[95px] overflow-hidden rounded-[8px]">
						<img
							className="w-full h-full object-cover"
							src={detail.logo}
							alt={detail.logo}
						/>
					</div>
					<div className="flex flex-col gap-[4px] w-full">
						<h2 className="font-700 sm:text-[14px] sm:leading-[19.6px] text-[12px] leading-[16.8px]">
							{detail.name}
						</h2>
						<div className="flex sm:gap-[8px] gap-[4px]">
							<Rating
								name="vet-rating"
								value={detail.rating || 0}
								precision={0.1}
								readOnly
								className="text-peeva-blue"
							/>
							<p className="font-400 text-[12px] leading-[16.8px] text-peeva-black-110">
								{detail.reviews} reviews
							</p>
						</div>
						<p className="font-400 sm:text-[14px] sm:leading-[21px] text-[12px] leading-[16.8px] text-peeva-black-110">
							{detail.phoneNumber}
						</p>
						<p className="font-400 sm:text-[14px] sm:leading-[21px] text-[12px] leading-[16.8px]  text-peeva-black-110">
							{detail.address1}
						</p>
					</div>
				</div>
				<div className="flex sm:justify-end justify-center sm:items-start items-center w-full">
					<CustomOutlinePrimaryButton onClick={() => handleAddVet(detail)}>
						Add vet
					</CustomOutlinePrimaryButton>
				</div>
			</Card>

		</>
	);
}

export default VetProfileCard;
