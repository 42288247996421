import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import CloseIcon from "@mui/icons-material/Close";
import utilityService from "@fuse/services/utilityService";
import {
	Avatar,
	Box,
	Switch,
	SwitchProps,
	TextareaAutosize,
	Typography,
	styled,
} from "@mui/material";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { AnyCnameRecord } from "dns";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";
import CardHeading from "@fuse/core/CardHeading/CardHeading";
import FieldLabel from "@fuse/core/FieldLabel/FieldLabel";
import React from "react";
import { BreedsType } from "../../types/BreedType";
import _ from "lodash";
import { DatePicker } from "@mui/x-date-pickers";
import petService from "@fuse/services/petService";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "app/store";
import { showMessage } from "app/store/fuse/messageSlice";
import { motion } from "framer-motion";
import PetChipConfirmationDialog from "@fuse/core/PetChipConfirmationDialog/PetChipConfirmationModal";

const BootstrapAutoComplete = styled(Autocomplete)(({ theme }: any) => ({
	"& .MuiAutocomplete-inputRoot": {
		borderRadius: 28,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		fontSize: 16,
		padding: "6px 16px",
		// transition: theme.transitions.create(['border-color', 'box-shadow']),
		boxShadow: "none",
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"Roboto Slab",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			borderRadius: 28,
			borderColor: "#000",
			boxShadow: "none",
		},
	},
	".Mui-focused": {
		boxShadow: "none",
	},
}));

const BootstrapSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	"& .MuiSwitch-switchBase": {
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#52BFF4",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#9DC1Fc" : "#9DC1Fc",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#52BFF4",
			border: "6px solid #52BFF4",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#EEE" : "#EEE",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

const schema = yup.object().shape({
	name: yup
		.string()
		.required("Pet Name is required")
		.min(2, "The Pet name must be at least 2 characters"),
	image: yup.string().optional(),
	chipId: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		//.required("Pet Chip is required")
		.min(9, "The chip number should be at least 9 digits."),
	confirmChip: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.when("chipId", (chipId, schema) => {
			if (chipId[0])
				return schema.required("Pet Chip is not confirmed")
			return schema
		}),
	speciesId: yup
		.string()
		.required("Pet Species is required")
		.min(1, "Pet Species is required"),
	chipTypeId: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.when("chipId", (chipId, schema) => {
			if (chipId[0])
				return schema.required("Chip Type is required")
			return schema
		})
		.min(1, "Chip Type is required"),
	breeds: yup.array().of(yup.string()).optional(),
	sex: yup.number().optional(),
	dob: yup.date().nullable().optional(),
	lastRabiesVaccine: yup.date().nullable().optional(),
	approximateWeight: yup
		.mixed()
		.test(
			"is-valid-number",
			"Approx. Weight must be a number",
			(value) =>
				value !== null || value !== undefined || typeof value !== "number"
		)
		.transform((value, originalValue) =>
			originalValue === "" ? undefined : value
		),
	spayedNeutered: yup.boolean(),
	shareContactInfo: yup.boolean(),
	notes: yup.string().optional(),
});
/**
 * The analytics dashboard app.
 */
function AddPetForm({ allSpecies, allBrands, loading }) {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [apiLoading, setApiLoading] = useState(false);
	const [showImageMessage, setShowImageMessage] = useState(false);
	const [notesLength, setNotesLength] = useState(0);
	const [allBreeds, setBreeds] = useState([]);
	const [drag, setDrag] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [submitData, setSubmitData] = useState<any>(null);

	var sexList = [
		{ id: 0, name: "Unknown" },
		{ id: 1, name: "Male" },
		{ id: 2, name: "Female" },
	];
	let zero: number = 0;
	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			name: "",
			chipId: "",
			confirmChip: "",
			speciesId: "",
			chipTypeId: "",
			breeds: [],
			dob: null,
			sex: 0,
			lastRabiesVaccine: null,
			approximateWeight: undefined,
			image: "",
			spayedNeutered: false,
			shareContactInfo: false,
			notes: "",
		},
		resolver: yupResolver(schema),
	});
	const {
		control,
		watch,
		trigger,
		reset,
		handleSubmit,
		formState,
		getValues,
		setValue,
		setError,
	} = methods;
	const { errors, isValid, dirtyFields } = formState;

	const notes = watch("notes");
	const speciesId = watch("speciesId");

	useEffect(() => {
		setNotesLength(notes?.length);
	}, [notes]);

	useEffect(() => {
		setBreeds([]);
		setValue("breeds", []);
	}, [speciesId]);
	const dragOver = (e) => {
		e.preventDefault();
		setDrag(true);
	};

	const dragEnter = (e) => {
		e.preventDefault();
		setDrag(true);
	};

	const dragLeave = (e) => {
		e.preventDefault();
		setDrag(false);
	};

	const fileDrop = (e, onChange) => {
		e.preventDefault();
		setDrag(false);
		const files = e.dataTransfer.files;
		if (files.length) {
			if (files.length > 1) {
				//TODO: Show Alert here
			}
			handleFile(files[0], onChange);
		}
	};

	const handleFile = (file, onChange) => {
		if (!file.type.startsWith("image/")) {
			alert("Only image files can be uploaded");
			return;
		}
		const reader: FileReader = new FileReader();
		reader.onload = () => {
			if (typeof reader.result === "string") {
				onChange(`data:${file.type};base64,${btoa(reader.result)}`);
			} else {
				// TODO: SHOW ERROR
			}
		};
		reader.onerror = () => {
			// TODO: SHOW ERROR
		};
		reader.readAsBinaryString(file);
	};
	// function onSubmit(data: any) {
	// 	AddPet(data);
	// }

	function handleDialogClose() {
		setOpenConfirmDialog(false);
	}

	function handleLPANavigate() {
		setOpenConfirmDialog(false);
		processFormSubmit(submitData);
	}

	function onSubmit(data: any) {
		setSubmitData(null);
		if (!data.chipId) {
			setOpenConfirmDialog(true);
			setSubmitData(data);
			return;
		} else {
			processFormSubmit(data);
		}
	}

	function processFormSubmit(data: any) {
		setApiLoading(true);
		petService
			.createPet(data)
			.then((res: any) => {
				setApiLoading(false);

				if (res.pet) {
					handlePetCreatedDatalayerPush(data);
				}

				if (res.pet && data.chipId) {
					navigate(`/client/pet/checkout/${res.pet.id}`);
				} else if (!data.chipId) {
					navigate("/client/add-alert/" + res.pet.id);
				}
			})
			.catch((_errors: string[]) => {
				setApiLoading(false);
				if (_errors.length > 0) {
					let chipIdErrors = _errors.filter(
						(error) =>
							error.includes("chip number") || error.includes("microchip id")
					);
					if (chipIdErrors) {
						_errors.forEach((error) => {
							setError("chipId", {
								type: "manual",
								message: error,
							});
						});
					} else {
						let apiError = _errors[0];
						if (apiError) {
							dispatch(
								showMessage({
									message: "Some error occurred, please contact our support.",
									variant: "error",
								})
							);
						}
					}
				}
			});
	}
	function onSpeciesChange(speciesId) {
		setApiLoading(true);

		utilityService
			.getPetBreedsBySpeciesId(speciesId)
			.then((res: any) => {
				setApiLoading(false);
				if (res.result) {
					setBreeds(res.result);
				}
			})
			.catch((error) => {
				console.log(error);
				setApiLoading(false);
			});
	}

	function handlePetCreatedDatalayerPush(pet: any) {
		try {
			const chipTypeId = pet.chipTypeId;
			const brand = allBrands.find((f) => f.id == pet.chipTypeId)?.name;

			//@ts-ignore
			window.dataLayer = window.dataLayer || [];
			//@ts-ignore
			window.dataLayer.push({
				event: "pet_created",
				pet_name: pet.name,
				microchip: pet.chipId,
				microchip_brand: brand,
			});
		} catch (e) {
			console.log(e)
		}
	}


	return (
		<>
			{(loading) && <FuseSplashScreen />}
			<div className="flex flex-col gap-[20px]">
				<CardHeading
					heading="Add a Pet"
					subHeading="Add information about your pet and click the “save and continue” button to select a plan. "
					customClassHeading="font-quicksand font-700 text-18px sm:text-22px leading-22px sm:leading-26px text-peeva-black"
				/>
				<form
					name="registerForm"
					noValidate
					className="flex w-full flex-col justify-center overflow-y-scroll gap-[20px]"
					id="signupform-form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="flex sm:flex-row flex-col w-full gap-[20px]">
						<div className="flex sm:justify-start align-start justify-center">
							<Controller
								control={control}
								name="image"
								render={({ field: { onChange, value } }) => (
									<Box
										className="relative flex items-center justify-center w-[200px] h-[200px] rounded-xl overflow-hidden border-dashed border-[2px] border-black"
										onDragOver={dragOver}
										onDragEnter={dragEnter}
										onDragLeave={dragLeave}
										onDrop={(e) => fileDrop(e, onChange)}
									>
										{/* <div className="absolute inset-0 bg-black bg-opacity-50 z-10" /> */}
										<div className="absolute inset-0 flex items-center justify-center z-20 w-full h-full cursor-pointer p-[40px_20px]">
											<div>
												<label
													htmlFor="pet-image"
													className="flex cursor-pointer"
												>
													<input
														accept="image/*"
														className="hidden"
														id="pet-image"
														type="file"
														onChange={async (e) => {
															function readFileAsync() {
																return new Promise((resolve, reject) => {
																	const file = e?.target?.files?.[0];
																	if (!file) {
																		return;
																	}
																	const reader: FileReader = new FileReader();

																	reader.onload = () => {
																		if (typeof reader.result === "string") {
																			resolve(
																				`data:${file.type};base64,${btoa(
																					reader.result
																				)}`
																			);

																			const base64String = `data:${file.type
																				};base64,${btoa(reader.result)}`;
																			var stringLength =
																				base64String.length -
																				"data:image/png;base64,".length;

																			var sizeInBytes =
																				4 *
																				Math.ceil(stringLength / 3) *
																				0.5624896334383812;
																			var sizeInMb =
																				sizeInBytes / (1000 * 1000);
																			if (sizeInMb > 2) {
																				setShowImageMessage(true);
																			} else {
																				setShowImageMessage(false);
																			}
																		} else {
																			reject(
																				new Error(
																					"File reading did not result in a string."
																				)
																			);
																		}
																	};
																	reader.onerror = reject;
																	reader.readAsBinaryString(file);
																});
															}
															const newImage = await readFileAsync();
															onChange(newImage);
														}}
													/>
													<FuseSvgIcon className="text-white opacity-10 text-opacity-0 w-full h-full">
														heroicons-outline:camera
													</FuseSvgIcon>
												</label>
											</div>
											<div className="absolute top-10 right-6 cursor-pointer">
												<CloseIcon
													className="text-32"
													onClick={() => {
														onChange("");
													}}
												/>
											</div>
										</div>
										<Avatar
											sx={{
												backgroundColor: "background.default",
												color: "text.secondary",
												borderRadius: 0,
											}}
											className="object-cover w-full h-full text-center text-[12px] font-700 leading-[24px]"
											src={value}
											alt={yup?.Schema?.name}
										>
											Drag and drop or click to upload your profile photo
										</Avatar>
									</Box>
								)}
							/>
						</div>
						<div className="flex flex-col w-full gap-[20px]">
							<div className="flex sm:flex-row flex-col w-full gap-[20px]">
								<div className="flex w-full flex-col sm:gap-[8px] gap-[4px]">
									<FieldLabel>Pet Name</FieldLabel>
									<Controller
										name="name"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												aria-label="Pet Name"
												autoFocus
												id="name"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors.name}
												helperText={errors?.name?.message as string}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col sm:gap-[8px] gap-[4px]">
									<FieldLabel>Microchip Type</FieldLabel>
									<Controller
										name="chipTypeId"
										control={control}
										defaultValue=""
										render={({ field: { onChange, value } }) => (
											<BootstrapAutoComplete
												// onChange={onChange}
												onChange={(
													event: any,
													newValue: { id: string; name: string }
												) => {
													onChange(newValue?.id);
												}}
												value={value || null}
												autoFocus
												options={allBrands}
												// groupBy={(option: any) => option?.country}
												getOptionLabel={(option: any) =>
													typeof option == "object"
														? option
															? option?.name
															: ""
														: allBrands.find((c) => c?.id == option)?.name ?? ""
												}
												renderInput={(params: any) => {
													return (
														<TextField
															{...params}
															autoFocus
															placeholder="Please Select"
															id="chipTypeId"
															name="chipTypeId"
															variant="standard"
															fullWidth
															InputProps={{
																...params.InputProps,
																disableUnderline: true,
															}}
															error={!!errors.chipTypeId}
															helperText={errors?.chipTypeId?.message as string}
														/>
													);
												}}
											/>
										)}
									/>
								</div>
							</div>

							<div className="flex sm:flex-row flex-col w-full gap-[20px]">
								<div className="flex w-full flex-col  sm:gap-[8px] gap-[4px]">
									<FieldLabel>Microchip ID</FieldLabel>
									<Controller
										name="chipId"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												autoFocus
												id="chipId"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors?.chipId}
												helperText={errors?.chipId?.message as string}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col  sm:gap-[8px] gap-[4px]">
									<FieldLabel>Confirm Microchip ID</FieldLabel>
									<Controller
										name="confirmChip"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												autoFocus
												id="confirmChip"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors.confirmChip}
												helperText={errors?.confirmChip?.message as string}
											/>
										)}
									/>
								</div>
							</div>

							<div className="flex sm:flex-row flex-col w-full gap-[20px]">
								<div className="flex w-full flex-col  sm:gap-[8px] gap-[4px]">
									<FieldLabel>Birth Date</FieldLabel>
									<Controller
										name="dob"
										control={control}
										render={({ field }) => (
											<DatePicker
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												autoFocus
												sx={{ border: "solid 2px black" }}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col  sm:gap-[8px] gap-[4px]">
									<FieldLabel>Sex</FieldLabel>
									<Controller
										name="sex"
										control={control}
										defaultValue={zero}
										render={({ field: { onChange, value } }) => (
											<BootstrapAutoComplete
												// onChange={onChange}
												onChange={(
													event: any,
													newValue: { id: string; name: string }
												) => {
													onChange(newValue?.id);
												}}
												value={value || null}
												autoFocus
												options={sexList}
												// groupBy={(option: any) => option?.country}
												getOptionLabel={(option: any) =>
													isNaN(option)
														? option
															? option?.name
															: ""
														: sexList.find((c) => c?.id == option)?.name ?? ""
												}
												renderInput={(params: any) => {
													return (
														<TextField
															{...params}
															autoFocus
															placeholder="Please Select"
															id="sex"
															variant="standard"
															fullWidth
															InputProps={{
																...params.InputProps,
																disableUnderline: true,
															}}
															error={!!errors.sex}
															helperText={errors?.sex?.message as string}
														/>
													);
												}}
											/>
										)}
									/>
								</div>
							</div>

							<div className="flex sm:flex-row flex-col w-full gap-[20px]">
								<div className="flex w-full flex-col  sm:gap-[8px] gap-[4px]">
									<FieldLabel>Species</FieldLabel>
									<Controller
										name="speciesId"
										control={control}
										render={({ field: { onChange, value } }) => (
											<BootstrapAutoComplete
												// onChange={onChange}
												onChange={(
													event: any,
													newValue: { id: string; name: string }
												) => {
													onChange(newValue?.id);
													if (newValue?.id) {
														onSpeciesChange(newValue?.id);
													}
												}}
												value={value || null}
												autoFocus
												options={allSpecies}
												// groupBy={(option: any) => option?.country}
												getOptionLabel={(option: any) =>
													typeof option == "object"
														? option
															? option?.name
															: ""
														: allSpecies.find((c) => c?.id == option)?.name ??
														""
												}
												renderInput={(params: any) => {
													return (
														<TextField
															{...params}
															autoFocus
															placeholder="Please Select"
															id="speciesId"
															name="speciesId"
															variant="standard"
															fullWidth
															InputProps={{
																...params.InputProps,
																disableUnderline: true,
															}}
															error={!!errors.speciesId}
															helperText={errors?.speciesId?.message as string}
														/>
													);
												}}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col  sm:gap-[8px] gap-[4px]">
									<FieldLabel>Breed(s) - Add up to 3</FieldLabel>
									<Controller
										name="breeds"
										control={control}
										render={({ field: { onChange, value } }) => (
											<BootstrapAutoComplete
												multiple
												freeSolo
												options={allBreeds}
												getOptionLabel={(option: any) => option?.name || ""}
												onChange={(event, newValue: any) => {
													if (newValue.length <= 3) {
														onChange(newValue.map((item) => item?.id));
													}
												}}
												value={
													value
														? ((value as any[]).map((id) =>
															_.find(allBreeds, { id })
														) as BreedsType)
														: ([] as BreedsType)
												}
												renderInput={(params) => (
													<TextField
														{...params}
														placeholder="Select Breeds"
														variant="outlined"
														InputLabelProps={{
															shrink: true,
														}}
														error={!!errors.breeds}
														helperText={errors?.breeds?.message as string}
													/>
												)}
											/>
										)}
									/>
								</div>
							</div>
							<div className="flex sm:flex-row flex-col w-full gap-[20px]">
								<div className="flex w-full flex-col  sm:gap-[8px] gap-[4px]">
									<FieldLabel>Last Rabies Vaccine</FieldLabel>
									<Controller
										name="lastRabiesVaccine"
										control={control}
										render={({ field }) => (
											<DatePicker
												{...field}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												autoFocus
												sx={{ border: "solid 2px black" }}
											/>
										)}
									/>
								</div>
								<div className="flex w-full flex-col  sm:gap-[8px] gap-[4px]">
									<FieldLabel>Approximate Weight (lbs)</FieldLabel>
									<Controller
										name="approximateWeight"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												type="number"
												inputProps={{
													step: 1.0, // This allows decimal inputs
												}}
												className="mb-20 bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
												required
												autoFocus
												id="approximateWeight"
												variant="outlined"
												fullWidth
												sx={{ border: "solid 2px black" }}
												error={!!errors?.approximateWeight}
												helperText={
													errors?.approximateWeight?.message as string
												}
											/>
										)}
									/>
								</div>
							</div>

							<div className="flex sm:flex-row flex-col w-full md:gap-[20px]">
								<div className="flex w-full flex-col">
									<Controller
										name="spayedNeutered"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div className="flex justify-between items-center h-full w-full mb-20">
												<FieldLabel>Spayed/neutered</FieldLabel>
												<BootstrapSwitch
													checked={value}
													onChange={(ev) => {
														onChange(ev.target.checked);
													}}
													aria-label="spayed or neutered?"
												/>
											</div>
										)}
									/>
								</div>

								<div className="flex w-full flex-col">
									<Controller
										name="shareContactInfo"
										control={control}
										render={({ field: { onChange, value } }) => (
											<div className="flex justify-between items-center h-full w-full mb-20">
												<FieldLabel>Share contact info</FieldLabel>
												<BootstrapSwitch
													checked={value}
													onChange={(ev) => {
														onChange(ev.target.checked);
													}}
													aria-label="share contact info"
												/>
											</div>
										)}
									/>
								</div>
							</div>
							<div className="flex w-full flex-col sm:gap-[8px] gap-[4px]">
								<FieldLabel>Pet Notes</FieldLabel>
								<Controller
									name="notes"
									control={control}
									render={({ field }) => (
										<TextareaAutosize
											{...field}
											minRows={5}
											maxRows={5}
											onChange={(event) => {
												if (event.target.value?.length <= 200) {
													field.onChange(event);
												}
											}}
											className=" bg-white rounded-[20px] p-[8px] border-[2px] border-black h-[44px] sm:h-[102px] text-14px sm:text-[16px] font-400 sm:font-400 leading-22px sm:leading-[24px] text-black placeholder-black"
											autoFocus
											id="notes"
										/>
									)}
								/>
								<p className="text-400 font-[14px] leading-[17.5px] w-full text-end pr-10">
									{notesLength}/200
								</p>
							</div>
						</div>
					</div>
					<div className="flex sm:justify-end justify-center">
						<div className="flex flex-col items-end">
							{apiLoading && (
								<motion.div
									className="max-w-[200px]"
									initial={{ opacity: 0, x: 20 }}
									animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
								>
									<CustomPrimaryButton disabled={true}>
										Saving ...
									</CustomPrimaryButton>
								</motion.div>
							)}
							{apiLoading && showImageMessage && (
								<Typography component="span" variant="body2" gutterBottom>
									If you've used a high-quality image for your pet, this may
									take a minute!
								</Typography>
							)}
						</div>
						{!apiLoading && (
							<CustomPrimaryButton
								onClick={(e) => {
									trigger();
								}}
							>
								Save and continue
							</CustomPrimaryButton>
						)}
					</div>
				</form>
			</div>
			{openConfirmDialog && (<PetChipConfirmationDialog open={openConfirmDialog} handleClose={handleDialogClose} handleLPANavigate={handleLPANavigate} />)}
		</>
	);
}

export default AddPetForm;
