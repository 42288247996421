import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import _ from "@lodash";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLayoutEffect, useState } from "react";
import { Alert, Autocomplete, Rating } from "@mui/material";
import { Stack } from "@mui/system";
import utilityService from "@fuse/services/utilityService";
import { IPetModel } from "@fuse/models";
import petService from "@fuse/services/petService";
import paymentService from "@fuse/services/paymentService";
import { IApiResponse } from "@fuse/models/user.models";
import PlanType from "../../types/plan.type";
import HTMLReactParser from "html-react-parser";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import JwtService from "../../services";
import { peevaBlue } from "@fuse/colors";
import PetChipConfirmationDialog from "@fuse/core/PetChipConfirmationDialog/PetChipConfirmationModal";
import { isNull } from "lodash";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	name: yup
		.string()
		.required("Pet Name is required")
		.min(2, "The Pet name must be at least 2 characters"),
	chipId: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		//.required("Pet Chip is required")
		.min(9, "The chip number should be at least 9 digits."),
	confirmChip: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.when("chipId", (chipId, schema) => {
			if (chipId[0])
				return schema.required("Pet Chip is not confirmed")
			return schema
		})
		//.required("Pet Chip is not confirmed")
		.oneOf([yup.ref("chipId"), null], "The chip numbers do not match"),
	chipTypeId: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.when("chipId", (chipId, schema) => {
			if (chipId[0])
				return schema.required("Chip Type is required")
			return schema
		})
		//.required("Chip Type is required")
		.min(1, "Chip Type is required"),
});

const CustomPrimaryButton = ({
	bgClass,
	roundedClass,
	children,
	disabled = false,
	onClick,
}) => {
	const bgClassName = bgClass ? bgClass : "bg-peeva-yellow";
	const roundedClassName = roundedClass ? roundedClass : "rounded-[32px]";
	const className = `w-full sm:w-auto sm:h-56px h-48px ${roundedClassName} ${bgClassName} border py-10 sm:text-16px font-700 sm:leading-[18.75px] text-14px leading-16px text-peeva-black`;
	return (
		<Button
			variant="contained"
			className={className}
			aria-label={children}
			type="submit"
			size="large"
			onClick={onClick}
		>
			{children}
		</Button>
	);
};

/**
 * The sign up page.
 */
function PetRegisterForm() {
	const navigate = useNavigate();
	const [customError, setCustomError] = useState("");
	const [loading, setLoading] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [submitData, setSubmitData] = useState<any>(null);

	const defaultValues = {
		name: (getPetStoredValues().name as string) || "",
		chipId: (getPetStoredValues().chipId as string) || "",
		confirmChip: (getPetStoredValues().confirmChip as string) || "",
		speciesId: (getPetStoredValues().speciesId as string) || "",
		chipTypeId: (getPetStoredValues().chipTypeId as string) || "",
	};

	const { control, formState, trigger, handleSubmit, setError } = useForm({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	const [allBrands, setBrands] = useState([]);

	useLayoutEffect(() => {
		//JwtService.init();
		JwtService.clearHistory();
		//	onBackFromSubscription();

		utilityService
			.getStaticData()
			.then((res: any) => {
				if (res.result) {
					setBrands(res.result.brands);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);


	const { errors } = formState;

	function handleDialogClose() {
		setOpenConfirmDialog(false);
	}

	function handleLPANavigate() {
		setOpenConfirmDialog(false);
		processFormSubmit(submitData);
	}

	function onSubmit(data: any) {
		setSubmitData(null);
		if (!data.chipId) {
			setOpenConfirmDialog(true);
			setSubmitData(data);
			return;
		} else {
			processFormSubmit(data);
		}
	}

	function processFormSubmit(data) {
		setLoading(true);

		petService
			.createPet(data)
			.then((res: any) => {
				if (res.pet.id && data.chipId) {
					if (res.enabled === "true") {
						navigate(`/pet-subscription/${res.pet.id}`);
					} else {
						const plan = res.plan as PlanType;
						onCardSelect(plan, res.pet.id);
					}
				}
				setLoading(false);
				sessionStorage.setItem("pet", JSON.stringify(data));
				handlePetCreatedDatalayerPush(data);
				if (!data.chipId) {
					navigate("/client/add-alert/" + res.pet.id);
				}
			})
			.catch((error: string[]) => {
				setLoading(false);
				setCustomError(error.join("<br />"));

				setTimeout(() => {
					setCustomError("");
				}, 10000);
			});
	}

	async function onCardSelect(plan: PlanType, petId: string) {
		setLoading(true);
		const resp = (await petService.updatePet(petId, {
			id: petId,
			planId: plan.id,
		})) as IPetModel;

		if (resp.id) {
			const checkout = (await paymentService.getCheckoutUrl(
				resp.id
			)) as IApiResponse;

			if (checkout.succeeded) {
				setLoading(false);
				handleDataLayerPush(plan);

				location.href = checkout.result.location;
			} else {
				setCustomError(checkout.errors[0]);

				setTimeout(() => {
					setCustomError("");
					setLoading(false);
				}, 5000);
			}
		} else {
			setLoading(false);
		}
	}

	function handleDataLayerPush(plan: PlanType) {
		//@ts-ignore
		window.dataLayer = window.dataLayer || [];
		//@ts-ignore
		window.dataLayer.push({
			event: "add_to_cart",
			value: plan.planPrice,
			currency: plan.currency,
			items: [
				{
					item_id: plan.planId,
					item_name: plan.name,
					item_variant: plan.priceId,
					price: plan.planPrice,
				},
			],
		});
	}

	function handlePetCreatedDatalayerPush(pet: any) {
		const chipTypeId = pet.chipTypeId;
		const brand = allBrands.find((f) => f.id == pet.chipTypeId)?.name;

		//@ts-ignore
		window.dataLayer = window.dataLayer || [];
		//@ts-ignore
		window.dataLayer.push({
			event: "pet_created",
			pet_name: pet.name,
			microchip: pet.chipId,
			microchip_brand: brand,
		});
	}

	async function redirectHome() {
		setLoading(true);
		const res = await JwtService.deactivateUser();
		setLoading(false);
		if (res.succeeded) {
			JwtService.logout();
			const session = sessionStorage.getItem("signup");
			if (session) {
				const { email } = JSON.parse(session);
				navigate(`/sign-up-form?email=${email}`);
			} else {
				navigate(`/sign-up`);
			}
		} else {
			if (res.errors?.length > 0) {
				setCustomError(res.errors[0]);
			} else {
				setCustomError("Can't process navigat back.");
			}
		}
	}

	function getPetStoredValues() {
		const session = sessionStorage.getItem("pet");

		if (session) {
			const pet = JSON.parse(session) as any;
			return pet;
		}
		return {} as any;
	}

	return (
		<>
			<div className="flex flex-col min-w-0 min-h-screen justify-center bg-white">
				<div className="flex flex-row min-w-screen pt-20 h-56 relative border-after border-after-56">
					<div
						className="flex items-center pl-10 w-1/4 relative cursor-pointer h-56px border-b-1"
						onClick={() => redirectHome()}
					>
						<ArrowBackIosIcon className="sm:text-16px text-14px font-700 leading-18px text-peeva-black-110" />{" "}
						<Typography className="sm:text-16px font-700 sm:leading-18px text-peeva-black-110 text-14px leading-16px">
							{" "}
							Back
						</Typography>
					</div>
					<div className="flex items-start justify-center w-2/4 h-56px border-b-1">
						<img
							className="h-40px w-[110px] flex mx-auto"
							src="assets/img/checkout-logo.svg"
							alt="logo"
						/>
					</div>
					<div className="flex items-start w-1/4 h-56px border-b-1"></div>
				</div>
				<div className="h-full w-full pt-64 px-16 py-8 justify-center sm:p-48 md:flex md:h-full md:p-64">
					<div className="mx-auto max-w-xl sm:mx-0 sm:w-480 w-320 relative">
						{customError && (
							<Stack className="pb-12" sx={{ width: "100%" }} spacing={2}>
								<Alert variant="filled" severity="error" onClose={() => { }}>
									<div>{HTMLReactParser(customError)}</div>
								</Alert>
							</Stack>
						)}

						{loading && <FuseSplashScreen />}
						<form
							name="registerForm"
							noValidate
							className="mt-32 flex w-full flex-col justify-center"
							id="petregister-form"
							onSubmit={handleSubmit(onSubmit)}
						>
							<Controller
								name="name"
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										className="mb-20 sm:mb-24 bg-[#F1F1F1] rounded-16 h-[58px] text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
										label="Your Pet's Name"
										autoFocus
										type="name"
										error={!!errors.name}
										helperText={errors?.name?.message}
										variant="outlined"
										required
										fullWidth
									/>
								)}
							/>
							<div className="flex gap-10">
								<Controller
									name="chipId"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-20 sm:mb-24 bg-[#F1F1F1] rounded-16 h-[58px] text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
											label="Microchip ID"
											type="name"
											error={!!errors.chipId}
											helperText={errors?.chipId?.message}
											variant="outlined"
											fullWidth
										/>
									)}
								/>

								<Controller
									name="confirmChip"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-20 sm:mb-24 bg-[#F1F1F1] rounded-16 h-[58px] text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
											label="Confirm Microchip ID"
											type="name"
											error={!!errors.confirmChip}
											helperText={errors?.confirmChip?.message}
											variant="outlined"
											fullWidth
										/>
									)}
								/>
							</div>

							<Controller
								name="chipTypeId"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										className="mb-20 sm:mb-24 bg-[#F1F1F1] rounded-16 h-[58px] text-16px font-400 md:font-700 leading-22px md:leading-22px text-black placeholder-black"
										freeSolo
										options={allBrands}
										getOptionLabel={(option: any) => option?.name || ""}
										onChange={(event, newValue) => {
											onChange(newValue?.id);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												placeholder="Select a Brand"
												required
												label="Microchip Brand"
												variant="outlined"
												name="chipTypeId"
												InputLabelProps={{
													shrink: true,
												}}
												error={!!errors.chipTypeId}
												helperText={errors?.chipTypeId?.message as string}
											/>
										)}
									/>
								)}
							/>
							<Typography className="my-20 text-12px font-400 leading-14px text-peeva-black md:text-left text-center">
								Select the brand of microchip that has been implanted in your pet.
								If you do not see the brand of microchip listed or you don’t know
								it, select “other” or “unknown.”
							</Typography>

							{/* <Button
							variant="contained"
							color="secondary"
							className="sm:h-56px h-48px rounded-16 bg-peeva-blue border hover:bg-peeva-blue-110 transition-colors duration-200 py-10 sm:text-16px font-700 sm:leading-18px text-14px leading-16px"
							aria-label="Continue to pet info"
							type="submit"
							size="large"
							id="petregister-submit-button"
							onClick={() => trigger()}
						>
							Continue to membership selection
						</Button> */}

							<CustomPrimaryButton
								bgClass="bg-peeva-blue hover:bg-peeva-blue-110 transition-colors duration-200 text-white"
								roundedClass="rounded-[16px]"
								onClick={() => {
									trigger();
								}}
							>
								Continue to membership selection
							</CustomPrimaryButton>

							<Typography className="mt-12 text-12px font-400 leading-14px text-peeva-black-120 md:text-left text-center">
								Peeva cares about your privacy. Your data will only be used to
								process your order and support your experience, as described in
								our
								<a
									href="https://peeva.co/privacy"
									className="!underline pl-2"
									role="button"
									target="_blank"
								>
									Privacy Policy
								</a>
								. Peeva will never sell, trade, or transfer your personal data to
								third-parties.
							</Typography>
							<div className="grid grid-cols-1 mt-20">
								<Rating
									name="read-only"
									value={5}
									readOnly
									size="large"
									className="flex justify-center color-red-900 my-8"
									sx={{
										"& .MuiRating-iconFilled": {
											color: peevaBlue.DEFAULT,
										},
									}}
								/>
								<Typography className="text-black text-12px font-700 leading-18px block mt-12 md:text-left text-center">
									“A wonderful and outstanding service! My dog was lost for almost
									a month and I was about to resign myself to him being gone when
									I received a notification from Peeva that a hit had come up on
									his chip almost 20 miles away!”
								</Typography>
								<Typography className="text-black block text-center text-12px font-400 leading-16px mt-6">
									- jpchiesa
								</Typography>
							</div>
							<div className="flex justify-center gap-2 mt-32">
								<img className="flex" src="assets/img/secure.svg" alt="logo" />
								<img className="flex" src="assets/img/stripe.svg" alt="logo" />
							</div>
						</form>
					</div>
				</div>
			</div>
			{openConfirmDialog && (<PetChipConfirmationDialog open={openConfirmDialog} handleClose={handleDialogClose} handleLPANavigate={handleLPANavigate} />)}

		</>
	);
}

export default PetRegisterForm;
