import PerksApp from "./PerksApp";

const PerksAppConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: 'client/perks',
			element: <PerksApp />
		},
		{
			path: 'client/member-perks',
			element: <PerksApp />
		}
	]
};

export default PerksAppConfig;
