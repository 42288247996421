import CardHeading from "@fuse/core/CardHeading/CardHeading";
import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";
import FieldLabel from "@fuse/core/FieldLabel/FieldLabel";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

export default function AddVetSearch({ onSearchCallBack }) {
	const [searchString, setSearchString] = useState("");
	// useEffect(() => {
	// 	const delayDebounceFn = setTimeout(() => {
	// 		onSearchCallBack(searchString);
	// 	}, 6000);

	// 	return () => clearTimeout(delayDebounceFn);
	// }, [searchString]);

	const onSearch = () => {
		onSearchCallBack(searchString);
	};

	return (
		<div className="flex flex-col gap-[20px]">
			<CardHeading
				heading="Add a Vet"
				customClassHeading="sm:text-[20px] sm:leading-[28px] font-700 text-peeva-black text-[18px] leading-[21.6px]"
			/>
			<div className="flex flex-col gap-[8px]">
				<FieldLabel customClass="flex w-full sm:text-[16px] sm:leading-[24px] text-[14px] leading-[19.6px] font-700 mb-4">
					Search by Practice Name, Address, or Zip Code
				</FieldLabel>
				<div className="flex sm:flex-row flex-col gap-[20px] items-center justify-center">
					<TextField
						onChange={(e) => {
							setSearchString(e.target.value);
						}}
						onKeyDown={(e) => {
							if (e.key == 'Enter') {
								setTimeout(() => {
									onSearch();
								}, 300);
							}
						}}
						value={searchString}
						className="bg-white rounded-24 h-[44px] sm:h-46px text-14px sm:text-16px font-400 sm:font-700 leading-22px sm:leading-22px text-black placeholder-black"
						autoFocus
						id="search-field"
						variant="outlined"
						fullWidth
						sx={{ border: "solid 2px black" }}
					/>
					<CustomPrimaryButton onClick={onSearch}>Search</CustomPrimaryButton>
				</div>
			</div>
		</div>
	);
}
