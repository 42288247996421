import { useState, useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	Autocomplete,
	Card,
	TextField,
} from "@mui/material";
import CardHeading from "@fuse/core/CardHeading/CardHeading";
import { styled } from "@mui/system";
import FieldLabel from "@fuse/core/FieldLabel/FieldLabel";
import CustomPrimaryButton from "@fuse/core/CustomPrimaryButton/CustomPrimaryButton";
import petService from "@fuse/services/petService";
import GoBackCommon from "@fuse/core/GoBackCommon/GoBackCommon";
import VetInfoCard from "../VetInfoCard/VetInfoCard";
import vetService from "@fuse/services/vetService";
import { useAppDispatch } from "app/store";
import { showMessage } from "app/store/fuse/messageSlice";

const BootstrapAutoComplete = styled(Autocomplete)(({ theme }: any) => ({
	"&": {
		width: "100%",
	},
	"& .MuiAutocomplete-inputRoot": {
		width: "100% !important",
		borderRadius: 28,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		fontSize: 16,
		padding: "6px 16px",
		// transition: theme.transitions.create(['border-color', 'box-shadow']),
		boxShadow: "none",
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"Roboto Slab",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			borderRadius: 28,
			borderColor: "#000",
			boxShadow: "none",
		},
	},
	".Mui-focused": {
		boxShadow: "none",
	},
}));

function AddVetOnPet() {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const param = useParams();
	const [userPets, setUserPets] = useState<any[]>([]);
	const [petId, setPetId] = useState(null);
	const [site, setSite] = useState(null);
	const [error, setError] = useState("");
	const [siteDetail, setSiteDetail] = useState<any>(null);
	const peevaSiteId = param.id;

	useLayoutEffect(() => {
		getUserPets();
		getPeevaSiteById();
	}, []);

	async function getUserPets() {
		const res = (await petService.getUserPets()) as Array<any>;
		if (res) {
			const arr = [];
			res.forEach((user) => {
				if (user.isActive) {
					arr.push(user);
				}
			});
			setUserPets(arr);
		}
	}

	async function getPeevaSiteById() {
		const res = (await vetService.getSiteById(peevaSiteId)) as any;
		if (res) {
			setSite(res.result);
			const detail = {} as any;
			detail.name = res.result.name;
			detail.logo = res.result.logo;
			detail.phoneNumber = res.result.phoneNumber;
			detail.address1 = res.result.address1;
			detail.rating = res.result.rating;
			detail.reviews = res.result.reviews;
			detail.reviewsCount = res.result.reviewsCount;
			setSiteDetail(detail);
		}
	}

	async function handleConfirm() {
		const petIds = [petId];
		const obj = {
			siteId: peevaSiteId,
			petIds,
		};
		const resp = (await vetService.addSiteToPets(obj)) as [];
		if (resp?.length > 0)
			dispatch(showMessage({ message: "Site(s) aded to pet" }));
		navigate(`/client/vet-info/profile/${peevaSiteId}`, { state: {} });
	}

	return (
		<div className="w-full p-[20px]">
			<div className="flex flex-col gap-[20px]">
				<GoBackCommon />
				<Card className="w-full p-[28px] flex flex-col gap-[20px]">
					<CardHeading
						heading="Add a vet"
						customClassHeading="font-700 text-[22px] leading-[26.4px]"
					/>
					<div className="flex flex-col gap-[20px]">
						<h2 className="font-700 sm:text-[16px] sm:leading-[22.4px]">
							You selected:
						</h2>
						{siteDetail && <VetInfoCard detail={siteDetail} />}

						<div className="flex flex-col gap-[8px] w-full">
							<FieldLabel customClass="flex w-full sm:text-[16px] sm:leading-[24px] text-[14px] leading-[19.6px] font-700 mb-4">
								Select a Pet to Assign the Vet
							</FieldLabel>
							<div className="flex flex-col sm:flex-row justify-center items-center gap-[20px]">
								<BootstrapAutoComplete
									autoFocus
									options={userPets}
									groupBy={(option: any) => option?.country}
									getOptionLabel={(option: any) =>
										isNaN(option)
											? option
												? option?.name
												: ""
											: userPets.find((c) => c?.id == option)?.name ?? ""
									}
									onChange={(event: any, newValue: any) => {
										setPetId(newValue?.id);
									}}
									renderInput={(params: any) => {
										return (
											<TextField
												{...params}
												autoFocus
												id="petId"
												variant="standard"
												fullWidth
												InputProps={{
													...params.InputProps,
													disableUnderline: true,
												}}
											/>
										);
									}}
								/>
								<CustomPrimaryButton onClick={handleConfirm}>
									Save
								</CustomPrimaryButton>
							</div>
						</div>
					</div>
				</Card>
			</div>
		</div>
	);
}

export default AddVetOnPet;
