import { useEffect } from "react";
import _ from "@lodash";
import { useAppDispatch, useAppSelector } from "app/store";
import { selectUser } from "app/store/user/userSlice";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import CardHeading from "@fuse/core/CardHeading/CardHeading";
import { Card, Typography } from "@mui/material";
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import { Link } from "react-router-dom";

function DashboardAppHeader() {
	const navigate = useNavigate();
	const user = useAppSelector(selectUser);

	function handleEditProfile() {
		navigate("/add-alert");
	}

	return (
		<>
			<Card className="flex flex-col w-full p-[28px] overflow-hidden gap-[20px] mt-3 rounded-[3.5rem] bg-[#ffa749]">
				<div className="flex flex-wrap gap-[20px]">
					<div className="flex items-center">
						<motion.div
							initial={{ x: 20, opacity: 0 }}
							animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
						>
							<StarsRoundedIcon className="text-32" />
						</motion.div>
					</div>
					<div className="flex">
						<motion.div
							initial={{ x: 20, opacity: 0 }}
							animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
						>
							<CardHeading
								heading="You Can Now Get Additional Perks With Your Pet's Peeva Enrollment!! "
								customClassHeading="font-quicksand font-700 text-18px sm:text-22px leading-18px sm:leading-26px text-peeva-black"
								subHeading=""
							/>
							<Typography className="font-400 sm:text-[16px] sm:leading-[24px] text-peeva-black text-[14px] leading-[17.5px] mt-8">
								<Link to="/client/member-perks" className="mr-4 text-blue-800" >Click here</Link>
								to redeem discounts on curated pet products and services that your pet needs most!
							</Typography>
						</motion.div>
					</div>


				</div>
			</Card>
		</>
	);
}

export default DashboardAppHeader;
