import { FuseNavigationType } from "@fuse/core/FuseNavigation/types/FuseNavigationType";

/**
 * The navigationConfig object is an array of navigation items for the Fuse application.
 */
const navigationConfig: FuseNavigationType = [
	{
		id: "dashboards",
		title: "Dashboards",
		subtitle: "Get All You Need",
		type: "group",
		icon: "heroicons-outline:home",
		translate: "DASHBOARDS",
		auth: ["customer"],
		children: [
			{
				id: "dashboards.project",
				title: "Dashboard",
				type: "item",
				icon: "dashboard.svg",
				url: "/client/dashboard",
			},
			{
				id: "dashboards.pets",
				title: "Pets",
				type: "item",
				icon: "pets.svg",
				url: "/client/pet",
			},
			{
				id: "dashboards.finance",
				title: "Veterinarians",
				type: "item",
				icon: "vets.svg",
				url: "/client/add-vet",
			},
			{
				id: "dashboards.profile",
				title: "Edit your profile",
				type: "item",
				icon: "heroicons-outline:user",
				iconClass: 'mr-[12px!important] p-[4px] ml-[-4px]',
				url: "/client/profile",
			},
			{
				id: "dashboards.petalert",
				title: "Lost Pet Alerts",
				type: "item",
				icon: "lpa.svg",
				url: "/client/add-alert"
			},
			{
				id: "dashboards.memberperks",
				title: "Peeva Member Perks",
				type: "item",
				icon: "perk.svg",
				url: "/client/member-perks",
				auth: ["paidcustomer"]
			},
		],
	},
	{
		id: "adminDashboard",
		title: "Dashboards",
		subtitle: "Admin Dashboard",
		type: "group",
		icon: "heroicons-outline:home",
		translate: "DASHBOARDS",
		auth: ["admin"],
		children: [
			{
				id: "admin.dashboard",
				title: "Dashboard",
				type: "item",
				icon: "heroicons-outline:clipboard-check",
				url: "/admin-dashboard",
			},
			{
				id: "admin.search",
				title: "Search",
				type: "item",
				icon: "heroicons-outline:clipboard-check",
				url: "/admin-search",
			},
			{
				id: "admin.sites",
				title: "Peeva Sites",
				type: "item",
				icon: "heroicons-outline:clipboard-check",
				url: "/admin-sites",
			},
			{
				id: "admin.sendemail",
				title: "Send Eamials",
				type: "item",
				icon: "heroicons-outline:clipboard-check",
				url: "/admin-send-email",
			},
			{
				id: "admin.bulkpetupload",
				title: "Pet Upload",
				type: "item",
				icon: "heroicons-outline:clipboard-check",
				url: "/bulk-pet-upload",
			},
		],
	},
];

export default navigationConfig;
