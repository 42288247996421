import { FuseRouteConfigsType } from "@fuse/utils/FuseUtils";
import DashboardAppConfig from "./dashboard/DashboardAppConfig";
import AddPetAppConfig from "./addpet/PetAppConfig";
import AddVetAppConfig from "./addvet/AddVetAppConfig";
import AddAlertAppConfig from "./addalert/AddAlertAppConfig";
import EditProfileAppConfig from "./editprofile/EditProfileAppConfig";
import PerksAppConfig from "./perks/PerksAppConfig";

const clientsConfigs: FuseRouteConfigsType = [
	DashboardAppConfig,
	PerksAppConfig,
	AddPetAppConfig,
	AddVetAppConfig,
	AddAlertAppConfig,
	EditProfileAppConfig,
];

export default clientsConfigs;
