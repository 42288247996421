import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import { RootStateType } from 'app/store/types';
import utilityService from '@fuse/services/utilityService';
import { PeevaSettingsType } from '../user';

type AppRootStateType = RootStateType<peevaSettingsSliceType>;


export const getPeevaSettings = createAppAsyncThunk('utility/settings', async () => {
	const response = await utilityService.getSettings();

	const data = (await response) as PeevaSettingsType;

	return data;
});

const initialState: PeevaSettingsType = {
	enableQRcodeUpsell: undefined,
};

export const peevaSettingsSlice = createSlice({
	name: 'utility/settings',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getPeevaSettings.fulfilled, (state, action) => action.payload);
	}
});

export const selectPeevaSettings = (state: any) => state.fuse.peevaSettings.result as PeevaSettingsType;

export type peevaSettingsSliceType = typeof peevaSettingsSlice;

export default peevaSettingsSlice.reducer;
